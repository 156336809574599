import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './Services.css'; // Ensure you have the correct path to your CSS file
function Services() {
  const [currentHeroIndex, setCurrentHeroIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const serviceRef = useRef(null);

  const heroData = [
   /* {
      
      image: "S4.jpg",
    },*/
    {
      
      image: "S2.jpg",
    },
    
    {
    
      image: "inje1.jpg",
    },
    /*
    {
      
      image: "S4.jpg",
    },*/
    {
     
      image: "S7.jpg",
    },
    {
      
      image: "S6.jpg",
    },
    /*{
      
      image: "S7.jpg",
    },*/
    {
     
      image: "S8.jpg",
    },
    {
     
      image: "S9.jpg",
    },
    {
      
      image: "S10.jpg",
    },
    /*{
      
      image: "S11.jpg",
    }, */{
      
      image: "S14.jpg",
    }, {
      
      image: "S15.jpg",
    },/* {
      
      image: "S16.jpg",
    },*/ {
      
      image: "S17.jpg",
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHeroIndex((prevIndex) => (prevIndex + 1) % heroData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);






  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.disconnect(); // Stop observing once it's visible
      }
    });
    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }
    return () => {
      if (serviceRef.current) {
        observer.unobserve(serviceRef.current);
      }
    };
  }, []);
  return (
    <div className="services-container">
       <nav className="navbar">
        <div className="logo-container">
        <img src="logooo1.png" alt="Logo" />
          <h1 className="nav-title">Al Rehman Dental Clinic</h1>
        </div>
        <ul className="nav-links">
        <li><Link to="/">Home</Link></li>
          <li><Link to="/about-us">About Us</Link></li>
          <li><Link to="/services">Our Services</Link></li>
          <li><Link to="/book-appointment">Book Appointment</Link></li>
          <li><Link to="/admin-login">Admin Login</Link></li>
        </ul>
        <div className="contact-info">
          <span>Call Us: 0326 4600245</span>
        </div>
      </nav> 

      <header className="hero-section" style={{ backgroundImage: `url(${heroData[currentHeroIndex].image})` }}>
        <h2>{heroData[currentHeroIndex].title}</h2>
        <p>{heroData[currentHeroIndex].subtitle}</p>
        <Link to="/book-appointment" className="btn">Book Appointment</Link>
      </header>







     {/* <section className="inner-banner">
        <img src={process.env.PUBLIC_URL + '/d1.jpg'} alt="Dental Services" className="inner-banner__image" />
        <h2 className="inner-banner__title">Services</h2>
        <ul className="thm-breadcrumb">
            <li className="thm-breadcrumb__item"><Link className="thm-breadcrumb__link" to="/">Home</Link></li>
            <li className="thm-breadcrumb__item current"><Link className="thm-breadcrumb__link" to="/services">Services</Link></li>
        </ul>
      </section>*/}

      <section className={`service-two ${isVisible ? 'animate' : ''}`} ref={serviceRef}>
        <div className="container">
          <div className="block-title text-left">
            <h2 className="block-title__title">Dental Services In Our Clinic</h2>
            <p className="block-title__text">
              Welcome to Al Rehman Dental Clinic, where your smile is our top priority. Our team of dedicated professionals offers a wide range of dental services tailored to meet the unique needs of each patient. From cosmetic enhancements to comprehensive dental care for children and adults, we are equipped with the latest technology to ensure you receive the highest quality treatment in a comfortable and friendly environment.
            </p>
          </div>
        </div>
      </section>









      <section className="service-section">
        <div className="container">
        <div class="block-title">
                    <h2 class="block-title__title">Meet Our Services</h2>
                    <p class="block-title__texttt">Explore our wide range of dental services tailored to meet your needs with advanced techniques and a personal touch.</p>
                </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="service-card">
                <h3 className="service-card__title">Cosmetic Dentistry</h3>
                <p className="service-card__text">
                  Transform your smile with our advanced cosmetic procedures including teeth whitening, veneers, and smile makeovers.
                </p>
                {/*<Link to="/services/cosmetic-dentistry" className="service-card__link">Learn More →</Link>*/}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-card">
                <h3 className="service-card__title">Pediatric Dentistry</h3>
                <p className="service-card__text">
                  Gentle and caring dental services for children, ensuring a positive experience for our younger patients.
                </p>
             
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-card">
                <h3 className="service-card__title">Dental Implants</h3>
                <p className="service-card__text">
                  Restoring your smile and confidence with state-of-the-art dental implant technology for lasting results.
                </p>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-card">
                <h3 className="service-card__title">Root Canal Treatment</h3>
                <p className="service-card__text">
                  Pain relief and preservation of your natural tooth through effective root canal therapy.
                </p>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-card">
                <h3 className="service-card__title">Crown and Bridges</h3>
                <p className="service-card__text">
                  Restorative solutions for damaged teeth, enhancing both function and aesthetics.
                </p>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-card">
                <h3 className="service-card__title">Braces and Aligners</h3>
                <p className="service-card__text">
                Transform your smile with confidence—our braces and aligners provide effective, discreet options for all ages..
                </p>
              
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-card">
                <h3 className="service-card__title">Dental Veneers</h3>
                <p className="service-card__text">
                  Enhance your smile with custom-made porcelain veneers for a natural look and feel.
                </p>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-card">
                <h3 className="service-card__title">Scaling & Polishing</h3>
                <p className="service-card__text">
                Keep your teeth sparkling and your gums healthy with our professional scaling and polishing services.
                </p>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-card">
                <h3 className="service-card__title">Teeth Whitening</h3>
                <p className="service-card__text">
                  Professional teeth whitening treatments for a brighter, whiter smile.
                </p>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-card">
                <h3 className="service-card__title">Wisdom Tooth Extraction</h3>
                <p className="service-card__text">
                  Safe and effective wisdom tooth extraction to prevent future dental issues.
                </p>
              </div>
              
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="service-card">
                <h3 className="service-card__title">Prosthodontics</h3>
                <p className="service-card__text">
                Restore your smile with our specialized prosthodontic solutions, including implants, crowns, bridges, and dentures.
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </section>

      <footer className="site-footer">
        <div className="container">
          <div className="footer-col">
            <div className="footer-widget">
              <h3 className="footer-widget__title">Useful Links</h3>
            <ul className="footer-widget__links-list">
  <li className="footer-widget__links-list-item"><Link to="/">Home</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/about-us">About Us</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/services">Our Services</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/book-appointment">Book an Appointment</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/contact-us">Contact Us</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/admin-login">Admin Login</Link></li>
</ul>
            </div>
            <div className="footer-widget">
              <h3 className="footer-widget__title">Time Schedule</h3>
              <ul className="footer-widget__time-list">
                <li className="footer-widget__time-list-item">Monday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Tuesday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Wednesday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Thursday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Friday Closed</li>
                <li className="footer-widget__time-list-item">Saturday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Sunday 10:30 am – 09:00 pm</li>
              </ul>
            </div>
            <div className="footer-widget">
              <h3 className="footer-widget__title">Get In Touch</h3>
              <ul className="footer-widget__time-list no-margin">
                <li className="footer-widget__time-list-item">Al-Rehman Dental Care, Khalid, Khalid Road, Road, near Goal Masjid, Sheikhupura, 39350</li>
                <li className="footer-widget__time-list-item">Pakistan</li>
                <li className="footer-widget__time-list-item">Phone: <a href="tel:0312 8345559">0326-4600245</a></li>
                <li className="footer-widget__time-list-item">Email: <a href="mailto:info@secondavedental.com">alrehmandentalcare6@gmail.com</a></li>
              </ul>
              <div className="footer-widget__social">
               
              <a href="https://www.instagram.com/al_rehman_dental_care?igsh=M2Q3NzE1c3huZWd1"><i className="fa fa-instagram"></i>
             <img src="/instagram.jpg" alt="Instagram" className="instagram-logo" />
  </a>
             </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <hr className="bottom-footer__line" />
            <div className="bottom-footer__wrap">
              <p className="bottom-footer__text">&copy; Sheikhupura. All right reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Services;
