import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AppointmentForm.css';
const AppointmentForm = () => {
    const [doctors, setDoctors] = useState([]);
    const [services, setServices] = useState([]);
    const [timeSlots, setTimeSlots] = useState([]);
    const [patientHistory, setPatientHistory] = useState([]);
    const [formData, setFormData] = useState({
        patient_name: '',
        email: '',
        phone: '',
        doctor_id: '',
        appointment_date: '',
        appointment_time: '',
        service: '',
        additional_message: '',
        checkup_number: '',
        visit_number: '1'
    });


    
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchDoctors();
    }, []);
    const fetchDoctors = async () => {
        try {
            const response = await axios.get('https://www.alrehmandentalcare.com/api/appointments/doctors');
            setDoctors(response.data);
        } catch (error) {
            setMessage('Error fetching doctors');
        }
    };
    const searchPatient = async (phone) => {
        try {
            const response = await axios.get(`https://www.alrehmandentalcare.com/api/appointments/patient/${phone}`);
            if (response.data.length > 0) {
                const patientData = response.data;
                setPatientHistory(patientData);
                
                // Get the most recent appointment
                const lastAppointment = patientData[patientData.length - 1];
                
                // Calculate next visit number
                const newVisitNumber = (parseInt(lastAppointment.visit_number) || 0) + 1;
    
                // Auto-fill form with patient's data
                setFormData(prev => ({
                    ...prev,
                    patient_name: lastAppointment.patient_name,
                    email: lastAppointment.email || '',
                    phone: lastAppointment.phone,
                    visit_number: newVisitNumber.toString()
                }));
    
                // Show previous appointments
                setMessage(`Found ${patientData.length} previous appointments for this patient.`);
            }
        } catch (error) {
            console.error('Error searching patient:', error);
        }
    };

    const fetchServices = async (doctorId) => {
        try {
            const response = await axios.get(`https://www.alrehmandentalcare.com/api/appointments/doctors/${doctorId}/services`);
            if (Array.isArray(response.data)) {
                setServices(response.data);
            } else {
                console.error('Unexpected response format:', response.data);
                setServices([]);
            }
        } catch (error) {
            console.error('Error fetching services:', error);
            setServices([]);
            setMessage('Error fetching services');
        }
    };



    const fetchTimeSlots = async (doctorId, date) => {
        try {
            const response = await axios.get(`https://www.alrehmandentalcare.com/api/appointments/doctors/${doctorId}/slots/${date}`);
            // Modify the time slots to include appointment numbers
            const slotsWithNumbers = response.data.map((slot, index) => ({
                ...slot,
                appointmentNumber: index + 1 // Adding appointment number based on time slot order
            }));
            setTimeSlots(slotsWithNumbers);
        } catch (error) {
            setMessage('Error fetching time slots');
        }
    };


   const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));


        if (name === 'doctor_id') {
            fetchServices(value);  // This is correct
            if (formData.appointment_date) {
                fetchTimeSlots(value, formData.appointment_date);
            }
        }



        // Search patient when phone number is entered
        if (name === 'phone' && value.length >= 10) {
            searchPatient(value);
        }

        if (name === 'doctor_id') {
            fetchServices(value);
            if (formData.appointment_date) {
                fetchTimeSlots(value, formData.appointment_date);
            }
        }
        if (name === 'appointment_date' && formData.doctor_id) {
            fetchTimeSlots(formData.doctor_id, value);
        }
        if (name === 'appointment_time') {
            const selectedSlot = timeSlots.find(slot => slot.value === value);
            setFormData(prev => ({
                ...prev,
                [name]: value,
                checkup_number: selectedSlot ? selectedSlot.appointmentNumber : ''
            }));
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            // Add patient history to the submission
            const submissionData = {
                ...formData,
                patient_history: patientHistory,
                checkup_number: formData.checkup_number
            };
            
            const response = await axios.post('https://www.alrehmandentalcare.com/api/appointments/create', submissionData);
            const selectedDoctor = doctors.find(d => d.id.toString() === formData.doctor_id.toString());
const successMessage = `Appointment Successfully Booked! Patient: ${formData.patient_name} Appointment #: ${formData.checkup_number} Doctor: ${selectedDoctor ? selectedDoctor.name : 'Unknown'} Date: ${formData.appointment_date} Time: ${formData.appointment_time} Service: ${formData.service}`;




            setMessage(successMessage);
            
            
            // Reset form
            setFormData({
                patient_name: '',
                email: '',
                phone: '',
                doctor_id: '',
                appointment_date: '',
                appointment_time: '',
                service: '',
                additional_message: '',
                checkup_number: '',
                visit_number: ''
            });
            setPatientHistory([]);
        } catch (error) {
            setMessage(error.response?.data?.message || 'Error booking appointment');
        }
        setLoading(false);
    };
    return (
        <div className="appointment-form-container">
            <h2>Book an Appointment</h2>

            {message && <div className="message">{message}</div>}
            <form onSubmit={handleSubmit}>


            {patientHistory.length > 0 && (
            <div className="previous-appointments">
                <h3>Previous Appointments</h3>
                <div className="appointments-list">
                    {patientHistory.map((apt, index) => (
                        <div key={index} className="appointment-item">
                            <p>Date: {apt.appointment_date}</p>
                            <p>Doctor: {apt.doctor_name}</p>
                            <p>Service: {apt.service}</p>
                            <p>Visit #: {apt.visit_number}</p>
                        </div>
                    ))}
                </div>
            </div>
        )}
                <div className="form-group">
                    <label>Patient Name:</label>
                    <input
                        type="text"
                        name="patient_name"
                        value={formData.patient_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
    <label>Email: (Optional)</label>
    <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        // required attribute remove kar diya
    />
</div>
                <div className="form-group">
                    <label>Phone:</label>
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Select Doctor:</label>
                    <select
                        name="doctor_id"
                        value={formData.doctor_id}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Select a doctor</option>
                        {doctors.map(doctor => (
                            <option key={doctor.id} value={doctor.id}>
                                {doctor.name}
                            </option>
                        ))}
                    </select>
                </div>

                {services.length > 0 && (
    <div className="form-group">
        <label>Select Service:</label>
        <select
            name="service"
            value={formData.service}
            onChange={handleInputChange}
            required
        >
            <option value="">Select a service</option>
            {services.map((service, index) => (
                <option key={index} value={service.service_name}>
                    {service.service_name}
                </option>
            ))}
        </select>
    </div>
)}

                <div className="form-group">
                    <label>Appointment Date:</label>
                    <input
                        type="date"
                        name="appointment_date"
                        value={formData.appointment_date}
                        onChange={handleInputChange}
                        min={new Date().toISOString().split('T')[0]}
                        required
                    />
                </div>

                {timeSlots.length > 0 && (
                    <div className="form-group">
                        <label>Available Time Slots:</label>
                        <select
                            name="appointment_time"
                            value={formData.appointment_time}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select a time slot</option>
                            {timeSlots.map((slot, index) => (
                                <option key={index} value={slot.value}>
                                    {slot.label}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                <div className="form-group">
                    <label>Appointment No:</label>
                    <input
                        type="number"
                        name="checkup_number"
                        value={formData.checkup_number}
                       // onChange={handleInputChange}
                        //required
                        readOnly
                    />
                </div>

                <div className="form-group">
               <label>Visit Number:</label>
            <input
                    type="number"
                    name="visit_number"
                    value={formData.visit_number}
                    onChange={handleInputChange}
                    required
             />
             </div>

                <div className="form-group">
                    <label>Additional Message:</label>
                    <textarea
                        name="additional_message"
                        value={formData.additional_message}
                        onChange={handleInputChange}
                    />
                </div>

                <button type="submit" disabled={loading}>
                    {loading ? 'Booking...' : 'Book Appointment'}
                </button>
            </form>
        </div>
    );
};

export default AppointmentForm;
