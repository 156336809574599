import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DoctorDashboard.css';

const DoctorDashboard = () => {
    const [appointments, setAppointments] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchAppointments();
    }, [selectedDate]);

    const fetchAppointments = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const url = selectedDate 
                ? `https://www.alrehmandentalcare.com/api/appointments/doctor-appointments?date=${selectedDate}`
                : 'https://www.alrehmandentalcare.com/api/appointments/doctor-appointments';
                
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setAppointments(response.data);
        } catch (error) {
            setMessage('Error fetching appointments');
        }
        setLoading(false);
    };

    return (
        <div className="doctor-dashboard">
            <h2>My Appointments</h2>
            {message && <div className="message">{message}</div>}
            
            <div className="date-filter">
                <input
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                />
            </div>

            {loading ? (
                <div>Loading...</div>
            ) : (
                <div className="appointments-list">
                    {appointments.map((appointment) => (
                        <div key={appointment.id} className="appointment-card">
                            <h3>Patient: {appointment.patient_name}</h3>
                            <p>Visit No: {appointment.visit_number}</p>
                           {/* <p>Appointment #: {appointment.checkup_number}</p>*/}
                            <p>Date: {appointment.formatted_date}</p>
                            <p>Time: {appointment.time}</p>
                            <p>Service: {appointment.service}</p>
                            <p>Phone: {appointment.phone}</p>

                            <p>Additional Message: {appointment.additional_message}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DoctorDashboard;
