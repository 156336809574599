
import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    const [currentHeroIndex, setCurrentHeroIndex] = useState(0);
    const [isDoctorVisible, setIsDoctorVisible] = useState(false);
    const [isFeatureVisible, setIsFeatureVisible] = useState(false);
    const [isTestimonialVisible, setIsTestimonialVisible] = useState(false); // New state to track visibility of testimonial section
    const doctorRef = useRef(null);
    const featureRef = useRef(null);
    const testimonialRef = useRef(null); // New ref for testimonial section
    
  const heroData = [
    {
      //title: "Your Smile, Our Priority",
      //subtitle: "Providing the best dental care for you and your family.",
      image: "hero11.jpg",
    },
    {
      //title: "Transforming smiles with expert care.",
      //subtitle: "Our goal for every patient is to create a beautiful smile.",
      image: "hero12.jpg",
    },
    {
      //title: "Transforming smiles with expert care.",
      //subtitle: "Our goal for every patient is to create a beautiful smile.",
      image: "h33.jpg",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHeroIndex((prevIndex) => (prevIndex + 1) % heroData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  // Observer for Doctor section
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsDoctorVisible(true);
        } else {
          setIsDoctorVisible(false);
        }
      });
    }, { threshold: 0.1 });

    if (doctorRef.current) {
      observer.observe(doctorRef.current);
    }

    return () => {
      if (doctorRef.current) {
        observer.unobserve(doctorRef.current);
      }
    };
  }, []);
  

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsTestimonialVisible(true); // Set visibility to true when section is in view
        } else {
          setIsTestimonialVisible(false); // Reset visibility when out of view
        }
      });
    }, { threshold: 0.1 });

    if (testimonialRef.current) {
      observer.observe(testimonialRef.current);
    }

    return () => {
      if (testimonialRef.current) {
        observer.unobserve(testimonialRef.current);
      }
    };
  }, []);

  // Observer for Feature section
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsFeatureVisible(true); // Set visibility to true when section is in view
        } else {
          setIsFeatureVisible(false); // Reset visibility when out of view
        }
      });
    }, { threshold: 0.1 });

    if (featureRef.current) {
      observer.observe(featureRef.current);
    }

    return () => {
      if (featureRef.current) {
        observer.unobserve(featureRef.current);
      }
    };
  }, []);

  return (
    <div className="dashboard-container">
       <nav className="navbar">
        <div className="logo-container">
          <img src="logooo1.png" alt="Logo" />
          <h1 className="nav-title">Al Rehman Dental Care</h1>
        </div>
        <ul className="nav-links">
        <li><Link to="/">Home</Link></li>
          <li><Link to="/about-us">About Us</Link></li>
          <li><Link to="/services">Our Services</Link></li>
          <li><Link to="/book-appointment">Book Appointment</Link></li>
          <li><Link to="/admin-login">Admin Login</Link></li>
        </ul>
        <div className="contact-info">
          <span>Call Us: 0326 4600245</span>
        </div>
      </nav>
      

    


      <header className="hero-section" style={{ backgroundImage: `url(${heroData[currentHeroIndex].image})` }}>
        <h2>{heroData[currentHeroIndex].title}</h2>
        <p>{heroData[currentHeroIndex].subtitle}</p>
        <Link to="/book-appointment" className="btn">Book Appointment</Link>
      </header>

      <section className="cta-two">
        <div className="container">
          <h2 className="cta-two__title">
            "Experiencing dental concerns? Reach out to us at "
            <a href="tel:0312834559" className="cta-two__highlight">0326 4600245  </a> 
               or make an 
            <Link to="/book-appointment" className="cta-two__highlight cta-two__link"> Appointment</Link>
          </h2>
        </div>
      </section>


      <section className="service-one">
  <div className="container">
    <div className="block-title text-center">
      <h5 className="block-title__title">Our Services</h5>
    </div>
    <div className="column-5__row">
      {[
        { title: "Cosmetic Dentistry", icon: "dental-1-1.png" },
        { title: "Pediatric Dentistry", icon: "dental-1-8.png" },
        { title: "Dental Implants", icon: "dental-1-4.png" },
        { title: "Root Canal Treatment", icon: "dental-1-2.png" },
        { title: "Crown and Bridges", icon: "dental-1-7.png" },
        { title: "Braces and Aligners", icon: "dental-1-3.png" },
        { title: "Dental Veneers", icon: "dental-1-5.png" },
        { title: "Scaling & Polishing", icon: "dental-1-6.png" },
        { title: "Teeth Whitening", icon: "dental-1-10.png" },
        { title: "Wisdom Tooth Extraction", icon: "dental-1-9.png" }
      ].map((service, index) => (
        <div className="column-5__col" key={index}>
          <div className="service-one__single">
            <div
              className="service-one__icon"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/${service.icon})`
              }}
            ></div>
            <h3 className="service-one__title">
              <a href="#">{service.title}</a>
            </h3>
          </div>
        </div>
      ))}
    </div>
    <div className="text-center">
      <a className="service-one__link" href="#">
        Providing expert care and precision to meet all your dental needs and goals
      </a>
    </div>
  </div>
</section>




      <section className="doctor-one" ref={doctorRef}>
        <div className="containerrr">
          <div className={`doctor-one__wrapper ${isDoctorVisible ? 'animate__animated animate__fadeIn' : ''}`}>
            <img
              src={`${process.env.PUBLIC_URL}/dradnan.png`}
              className={`doctor-one__person ${isDoctorVisible ? 'animate__animated animate__zoomIn' : ''}`}
              alt="Doctor"
            />
            <div className={`doctor-one__content ${isDoctorVisible ? 'animate__animated animate__slideInRight' : ''}`}>
              <span className="doctor-one__tag-line">Meet Our Doctor</span>
              <h3 className="doctor-one__title">Dr. Muhammad Adnan Akram</h3>
              <p className="doctor-one__text">
                Our clinic has established itself as a leading provider of exceptional dental care, offering advanced solutions for tooth loss, cosmetic dentistry, and comprehensive restorative procedures. We are committed to utilizing the latest technology and techniques to ensure optimal results, while prioritizing the comfort and satisfaction of our patients. Your journey to a healthier, more radiant smile begins with us.
              </p>
              <p className="doctor-one__text">
                "At Al Rehman Dental Clinic, your smile is our passion, and we are here to support your dental health journey with expertise and care."
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-one" ref={testimonialRef}>
        <div className="container">
          <div className="block-title text-center">
            <h5 className="block-title__title">What Our Clients Says</h5>
          </div>
          <div className="row">
            <div className={`col-lg-4 ${isTestimonialVisible ? 'animate__animated animate__fadeInLeft' : ''}`}>
              <div className="testimonial-one__single">
                <div className="testimonial-one__text">
                  "I was anxious about my first visit, but the welcoming atmosphere and professionalism of the team made me feel at ease. The dental implant work was outstanding. Highly recommend!"
                </div>
                <div className="testimonial-one__info">
                  <h3 className="testimonial-one__name">Ali Raza</h3>
                </div>
              </div>
            </div>
            <div className={`col-lg-4 ${isTestimonialVisible ? 'animate__animated animate__fadeInUp' : ''}`}>
              <div className="testimonial-one__single">
                <div className="testimonial-one__text">
                  "From the moment I arrived, I knew this was the best dental clinic! The staff genuinely cares about their patients. I had cosmetic work done, and I'm thrilled with the results!"
                </div>
                <div className="testimonial-one__info">
                  <h3 className="testimonial-one__name">Fatima Sohail</h3>
                </div>
              </div>
            </div>
            <div className={`col-lg-4 ${isTestimonialVisible ? 'animate__animated animate__fadeInRight' : ''}`}>
              <div className="testimonial-one__single">
                <div className="testimonial-one__text">
                  "I recently underwent a root canal at Al Rehman Dental Clinic, and I was pleasantly surprised by the smooth process. Dr.Adnan was gentle and ensured my comfort throughout."
                </div>
                <div className="testimonial-one__info">
                  <h3 className="testimonial-one__name">Omar Junaid</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





      <section className="feature-one" ref={featureRef}>
        <div className="containerrr">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className={`feature-one__box ${isFeatureVisible ? 'animate__animated animate__fadeInLeft' : ''}`}>
                <h3 className="feature-one__box-title">Who We Are</h3>
              </div>
            </div>
            <div className="col-lg-8">
              <div className={`feature-one__content ${isFeatureVisible ? 'animate__animated animate__fadeInRight' : ''}`}>
                <p>
                  At Al Rehman Dental Clinic, we pride ourselves on delivering exceptional dental care in a warm and welcoming environment. Our dedicated team of experienced professionals is committed to utilizing cutting-edge technology and the latest techniques to ensure each patient receives the highest standard of treatment. From routine check-ups to complex procedures, we prioritize your oral health and strive to enhance your smile. Your comfort and satisfaction are our foremost concerns, making us your trusted partner in dental wellness.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>



{/*
   <div className="google-map__home">
  <a href="https://www.google.com/maps/dir//Al-Rehman+Dental+Care,+Khalid,+Khalid+Road,+Road,+near+Goal+Masjid,+Sheikhupura,+39350,+Pakistan" target="_blank" rel="noopener noreferrer">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3406.912448071992!2d73.94725891526996!3d31.809749694560977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3917a10ddd542c0d%3A0xd4d9e7f212ed2d1f!2sAl-Rehman%20Dental%20Care!5e0!3m2!1sen!2sca!4v1684949490592!5m2!1sen!2sca"
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      style={{ width: "100%", height: "400px", border: "0" }}
    ></iframe>
  </a>
</div>

*/}
      <footer className="site-footer">
        <div className="container">
          <div className="footer-col">
            <div className="footer-widget">
              <h3 className="footer-widget__title">Useful Links</h3>
              <ul className="footer-widget__links-list">
  <li className="footer-widget__links-list-item"><Link to="/">Home</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/about-us">About Us</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/services">Our Services</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/book-appointment">Book an Appointment</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/contact-us">Contact Us</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/admin-login">Admin Login</Link></li>
</ul>
            </div>
            <div className="footer-widget">
              <h3 className="footer-widget__title">Time Schedule</h3>
              <ul className="footer-widget__time-list">
                <li className="footer-widget__time-list-item">Monday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Tuesday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Wednesday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Thursday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Friday Closed</li>
                <li className="footer-widget__time-list-item">Saturday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Sunday 10:30 am – 09:00 pm</li>
              </ul>
            </div>
            <div className="footer-widget">
              <h3 className="footer-widget__title">Get In Touch</h3>
              <ul className="footer-widget__time-list no-margin">
                <li className="footer-widget__time-list-item">Al-Rehman Dental Care,  Khalid Road,  near Goal Masjid, Sheikhupura, 39350</li>
                <li className="footer-widget__time-list-item">Pakistan</li>
                <li className="footer-widget__time-list-item">Phone: <a href="tel:0312 8345559">0326-4600245</a></li>
                <li className="footer-widget__time-list-item">Email: <a href="mailto:info@secondavedental.com">alrehmandentalcare6@gmail.com</a></li>
              </ul>

           
             
              <div className="footer-widget__social">
               
              <a href="https://www.instagram.com/al_rehman_dental_care?igsh=M2Q3NzE1c3huZWd1"><i className="fa fa-instagram"></i>
             <img src="/instagram.jpg" alt="Instagram" className="instagram-logo" />
  </a>
             </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <hr className="bottom-footer__line" />
            <div className="bottom-footer__wrap">
              <p className="bottom-footer__text">&copy; Sheikhupura. All right reserved.</p>
            </div>
          </div>
        </div>
      </footer>




{/*
<div className="google-map__home">
  <a href="https://www.google.com/maps/place/Al-Rehman+Dental+Care/@31.8097497,73.9472589,17z/data=!3m1!4b1!4m6!3m5!1s0x3917a10ddd542c0d:0xd4d9e7f212ed2d1f!8m2!3d31.809749694560977!4d73.94725891526996!16s%2Fg%2F11h_dq_81q" target="_blank" rel="noopener noreferrer">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3406.912448071992!2d73.94725891526996!3d31.809749694560977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3917a10ddd542c0d%3A0xd4d9e7f212ed2d1f!2sAl-Rehman%20Dental%20Care!5e0!3m2!1sen!2sca!4v1684949490592!5m2!1sen!2sca"
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      style={{ width: "100%", height: "400px", border: "0" }}
    ></iframe>
  </a>
</div>*/}

    </div>
  );
};

export default Dashboard;





















