import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './AboutUs.css';
function AboutUs() {
  const [currentNumbers, setCurrentNumbers] = useState([]);

  useEffect(() => {
    const fetchCurrentNumbers = async () => {
      try {
        const response = await axios.get('https://www.alrehmandentalcare.com/api/current-numbers'); 
        setCurrentNumbers(response.data);
      } catch (error) {
        console.error('Error fetching current numbers:', error);
      }
    };

    fetchCurrentNumbers();
    const interval = setInterval(fetchCurrentNumbers, 30000);

    return () => clearInterval(interval);
  }, []); 
  return (
    <div className="dashboarddd-container">
      <nav className="navbar">
        <div className="logo-container">
        <img src="logooo1.png" alt="Logo" />
          <h1 className="nav-title">Al Rehman Dental Clinic</h1>
        </div>
        <ul className="nav-links">
        <li><Link to="/">Home</Link></li>
          <li><Link to="/about-us">About Us</Link></li>
          <li><Link to="/services">Our Services</Link></li>
          <li><Link to="/book-appointment">Book Appointment</Link></li>
          <li><Link to="/admin-login">Admin Login</Link></li>
        </ul>
        <div className="contact-info">
          <span>Call Us: 0326 4600245</span>
        </div>
      </nav>
     <section className="inner-banner">
        <img src={process.env.PUBLIC_URL + '/front2.jpg'} alt="Dental Services" className="inner-banner__image" />
        <h2 className="inner-banner__title">About Us</h2>
        <ul className="thm-breadcrumb">
          <li className="thm-breadcrumb__item"><Link className="thm-breadcrumb__link" to="/">Home</Link></li>
          <li className="thm-breadcrumb__item current"><Link className="thm-breadcrumb__link" to="/AboutUs">AboutUs</Link></li>
        </ul>
      </section>
      <section className="about-section">
        <div className="floating-shapes">
          <div className="shape" style={{top: '10%', left: '10%', width: '50px', height: '50px'}}></div>
          <div className="shape" style={{top: '20%', right: '20%', width: '70px', height: '70px'}}></div>
          <div className="shape" style={{bottom: '30%', left: '30%', width: '40px', height: '40px'}}></div>
          <div className="shape" style={{bottom: '10%', right: '10%', width: '60px', height: '60px'}}></div>
        </div>
        
        <div className="about-container">
          <div className="about-content">
            <h2 className="about-title">About Our Clinic</h2>
            <p className="about-text">
              At Al Rehman Dental Clinic, we are dedicated to offering a full spectrum of dental care, tailored to meet the diverse needs of our patients. From cosmetic dentistry to enhance your smile, to children's dentistry offering gentle care for young patients, our experienced team provides top-tier services.
            </p>
            <p className="about-text">
              We specialize in many procedures: root canal treatments, crowns and bridges, and braces and aligners, addressing a range of restorative and orthodontic needs. Additionally, our clinic excels in dental veneers, scaling and polishing, teeth whitening, and wisdom tooth extractions, ensuring comprehensive care for both aesthetic and health-related requirements.
            </p>
          </div>
          
          <div className="about-image">
            <div className="about-image-frame">
              <img src="abo.jpg" alt="Dental Clinic Interior" />
            </div>
          </div>
        </div>
      </section>
      <section className="current-numbers">
  <div className="container">
    <h1>Current Appointment Numbers</h1>
    <div className="doctor-numbers">
      {currentNumbers.map((doc) => (
        <div key={doc.doctor_name} className="doctor-number-card">
          <h3>{doc.doctor_name}</h3>
          <p 
            className="current-number-highlight"
            style={{ display: doc.current_appointment_number ? 'block' : 'none' }}
          >
            Current Number: {doc.current_appointment_number}
          </p>
          <p>Last Updated: {new Date(doc.last_updated).toLocaleTimeString()}</p>
        </div>
      ))}
    </div>
  </div>
</section>
      <section className="vision-mission">
        <div className="vision-block">
          <div className="content-card">
            <h2>Vision</h2>
            <div className="blue-line"></div>
            <p> To be a leading dental care provider recognized for our commitment to excellence, innovation, and patient-centered care. We strive to create a healthier community by promoting lifelong oral health and empowering our patients to achieve confident, radiant smiles .</p>
          </div>
          <div className="image-card">
            <img src="/s22.jpg" alt="Vision representation" />
          </div>
        </div>
        <div className="mission-block">
          <div className="image-card">
            <img src="s33.jpg" alt="Mission representation" />
          </div>
          <div className="content-card">
            <h2>Mission</h2>
            <div className="blue-line"></div>
            <p>Our mission is to provide exceptional dental care through personalized treatment plans, the latest dental techniques, and a compassionate approach. We are dedicated to enhancing our patients' oral health and overall well-being in a comfortable and welcoming environment. </p>
          </div>
        </div>
      </section>
<section className="doctors-section">
  <div className="section-header">
    <h2>Meet Our Expert Doctors</h2>
    <p>Our team of highly qualified medical professionals is dedicated to providing you with the best healthcare services.</p>
  </div>

  <div className="doctors-container">
    <div className="doctor-card" data-aos="fade-up" data-aos-delay="100">
      <div className="doctor-image">
        <img src="dradnan.png" alt="Dr. Adnan" />
      </div>
      <div className="doctor-info">
        <h3 className="doctor-name">Dr. Muhammad Adnan Akram</h3>
        <div className="doctor-qualification">BDS, MDS,RDS </div>
        <p className="doctor-specialty">Oral and Maxillofacial Surgeon , Assistant Professor</p>
        <div className="doctor-experience">
          <span className="experience-icon">⭐</span>
          17+ Years Experience
        </div>
      </div>
    </div>

    <div className="doctor-card" data-aos="fade-up" data-aos-delay="200">
      <div className="doctor-image">
        <img src="doccz.png" alt="Dr. Zirva Rehman" />
      </div>
      <div className="doctor-info">
        <h3 className="doctor-name">Dr. Zirva Rehman</h3>
        <div className="doctor-qualification">BDS, RDS</div>
        <p className="doctor-specialty">Dental Surgeon</p>
        <div className="doctor-experience">
          <span className="experience-icon">⭐</span>
          4+ Years Experience
        </div>
      </div>
    </div>

    <div className="doctor-card" data-aos="fade-up" data-aos-delay="300">
      <div className="doctor-image">
        <img src="drmai.jpg" alt="Dr.  Mamoina" />
      </div>
      <div className="doctor-info">
        <h3 className="doctor-name">Dr. Maimoona </h3>
        <div className="doctor-qualification">BDS, RDS</div>
        <p className="doctor-specialty">Dental Surgeon</p>
        <div className="doctor-experience">
          <span className="experience-icon">⭐</span>
          3+ Years Experience
        </div>
      </div>
    </div>

    <div className="doctor-card" data-aos="fade-up" data-aos-delay="400">
      <div className="doctor-image">
        <img src="doccc1.png" alt="Dr. Maha" />
      </div>
      <div className="doctor-info">
        <h3 className="doctor-name">Dr. Maha Junaid</h3>
        <div className="doctor-qualification">BDS, RDS</div>
        <p className="doctor-specialty">Dental Surgeon</p>
        <div className="doctor-experience">
          <span className="experience-icon">⭐</span>
          7+ Years Experience
        </div>
      </div>
    </div>

    <div className="doctor-card" data-aos="fade-up" data-aos-delay="500">
      <div className="doctor-image">
        <img src="doct1.png" alt="Dr. Junaid" />
      </div>
      <div className="doctor-info">
        <h3 className="doctor-name">Dr. Junaid khan</h3>
        <div className="doctor-qualification">BDS, RDS</div>
        <p className="doctor-specialty">Dental Surgeon</p>
        <div className="doctor-experience">
          <span className="experience-icon">⭐</span>
          7+ Years Experience
        </div>
      </div>
    </div>
  </div>
</section>



      <footer className="site-footer">
        <div className="container">
          <div className="footer-col">
            <div className="footer-widget">
              <h3 className="footer-widget__title">Useful Links</h3>

             <ul className="footer-widget__links-list">
  <li className="footer-widget__links-list-item"><Link to="/">Home</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/about-us">About Us</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/services">Our Services</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/book-appointment">Book an Appointment</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/contact-us">Contact Us</Link></li>
  <li className="footer-widget__links-list-item"><Link to="/admin-login">Admin Login</Link></li>
</ul>
            </div>
            <div className="footer-widget">
              <h3 className="footer-widget__title">Time Schedule</h3>
              <ul className="footer-widget__time-list">
                <li className="footer-widget__time-list-item">Monday 10:30 am –  09:00 pm</li>
                <li className="footer-widget__time-list-item">Tuesday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Wednesday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Thursday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Friday Closed</li>
                <li className="footer-widget__time-list-item">Saturday 10:30 am – 09:00 pm</li>
                <li className="footer-widget__time-list-item">Sunday 10:30 am – 09:00 pm</li>
              </ul>
            </div>
            <div className="footer-widget">
              <h3 className="footer-widget__title">Get In Touch</h3>
              <ul className="footer-widget__time-list no-margin">
                <li className="footer-widget__time-list-item">Al-Rehman Dental Care, Khalid, Khalid Road, Road, near Goal Masjid, Sheikhupura, 39350</li>
                <li className="footer-widget__time-list-item">Pakistan</li>
                <li className="footer-widget__time-list-item">Phone: <a href="tel:0312 8345559">0326 4600245</a></li>
                <li className="footer-widget__time-list-item">Email: <a href="mailto:info@secondavedental.com">alrehmandentalcare6@gmail.com</a></li>
              </ul>
              <div className="footer-widget__social">
               
               <a href="https://www.instagram.com/al_rehman_dental_care?igsh=M2Q3NzE1c3huZWd1"><i className="fa fa-instagram"></i>
              <img src="/instagram.jpg" alt="Instagram" className="instagram-logo" />
   </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <hr className="bottom-footer__line" />
            <div className="bottom-footer__wrap">
              <p className="bottom-footer__text">&copy; Sheikhupura. All right reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  
  );
}

export default AboutUs;
