
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminDashboard.css';

const formatDateForUpdate = (date) => {
const d = new Date(date);
return d.toISOString().split('T')[0];
};
const AdminDashboard = () => {
const [currentNumbers, setCurrentNumbers] = useState([]);
const [appointments, setAppointments] = useState([]);
const [searchTerm, setSearchTerm] = useState('');
const [searchDate, setSearchDate] = useState('');
const [loading, setLoading] = useState(false);
const [message, setMessage] = useState('');
const [showUpdateModal, setShowUpdateModal] = useState(false);
const [selectedAppointment, setSelectedAppointment] = useState(null);
const [updateFormData, setUpdateFormData] = useState({
patient_name: '',
email: '',
phone: '',
appointment_date: '',
appointment_time: '',
service: '',
additional_message: '',
visit_number: ''
});
const [showRecommendationModal, setShowRecommendationModal] = useState(false);
const [recommendationData, setRecommendationData] = useState({
appointment_id: '',
patient_name: '',
phone_number: '',
visit_number: '',
medical_history: [], // Changed to array
treatment_given: [], // Changed to array
treatment_plan: '',
medications: '',
 payment: ''
});
const medicalHistoryOptions = [
    'Hypertension', 'Nill', 'Tuberculosis', 'Diabetes Mellitus',
    'Liver Disease', 'Hepatits A,B,C', 'Thytoid Problem', 'Asthma',
    'Stomach Problem', 'Bleeding Disorder', 'Kidney Disease',
    'Pregnancy(Specify Trimester)', 'Heart Problem', 'Latex Allergy',
    'Any Drug Allergy', 'On other medication'
];

const treatmentOptions = [
    'Dental Infection', 'Dental Restroration', 'Minor Dental/Oral Surgery',
    'Gums/Periodontitis Treatment', 'Root Canal Therapy', 'Scaling and polishing',
    'Tooth Extraction', 'Crown and Brides', 'Other', 'Nill'
];

const handleCheckboxChange = (field, value) => {
    setRecommendationData(prev => {
        const currentValues = prev[field];
        const newValues = currentValues.includes(value)
            ? currentValues.filter(item => item !== value)
            : [...currentValues, value];
        
        return {
            ...prev,
            [field]: newValues
        };
    });
};
useEffect(() => {
    fetchAppointments();
    fetchCurrentNumbers();
}, []);
const fetchAppointments = async () => {
    setLoading(true);
    try {
        const token = localStorage.getItem('token');
        const appointmentsResponse = await axios.get('https://www.alrehmandentalcare.com/api/appointments/all', {
            headers: { Authorization: `Bearer ${token}` }

        });
        const recommendationsResponse = await axios.get('https://www.alrehmandentalcare.com/api/appointments/recommendations', {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (appointmentsResponse.data && Array.isArray(appointmentsResponse.data)) {
            const recommendations = recommendationsResponse.data || [];
            
            const appointmentsWithRecommendations = appointmentsResponse.data.map(apt => {
                const recommendation = recommendations.find(rec => rec.appointment_id === apt.id);
                return {
                    ...apt,
                    medical_history: recommendation?.medical_history || '',
                    treatment_given: recommendation?.treatment_given || '',
                    treatment_plan: recommendation?.treatment_plan || '',
                    medications: recommendation?.medications || '',
                     payment:recommendation?.payment||''
                };
            });
            
            setAppointments(appointmentsWithRecommendations);
        } else {
            setAppointments([]);
            setMessage('No appointments found');
        }
    } catch (error) {
        console.error('Fetch error:', error);
        setMessage('Error fetching appointments');
        setAppointments([]);
    }
    setLoading(false);
};

const handleRecommendationSubmit = async (e) => {
    e.preventDefault();
    try {
        const token = localStorage.getItem('token');
        const formattedData = {
            ...recommendationData,
            medical_history: Array.isArray(recommendationData.medical_history) ? 
                JSON.stringify(recommendationData.medical_history) : '[]',
            treatment_given: Array.isArray(recommendationData.treatment_given) ?
                JSON.stringify(recommendationData.treatment_given) : '[]',
            payment: recommendationData.payment || ''
        };
        
        // Check if a recommendation already exists
        const existingAppointment = appointments.find(apt => apt.id === recommendationData.appointment_id);
        const hasExistingRecommendation = existingAppointment && 
            (existingAppointment.medical_history || 
             existingAppointment.treatment_given || 
             existingAppointment.treatment_plan || 
             existingAppointment.payment || 
             existingAppointment.medications);


        const url = hasExistingRecommendation 
            ? `https://www.alrehmandentalcare.com/api/appointments/recommendation/${recommendationData.appointment_id}`
            : 'https://www.alrehmandentalcare.com/api/appointments/recommendation';

        const method = hasExistingRecommendation ? 'put' : 'post';

        const response = await axios({
            method,
            url,
            data: formattedData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        
        if (response.data) {
            setMessage(`Recommendation ${hasExistingRecommendation ? 'updated' : 'saved'} successfully`);
            setShowRecommendationModal(false);
            await fetchAppointments();
        }
    } catch (error) {
        console.error('Recommendation error:', error);
        setMessage(error.response?.data?.message || 'Error saving recommendation');
    }
};



const handleOpenRecommendationUpdate = (appointment) => {
    try {
        // Initialize empty arrays as defaults
        const medical_history = appointment.medical_history ? 
            (typeof appointment.medical_history === 'string' ? 
                JSON.parse(appointment.medical_history) : 
                appointment.medical_history) : [];

        const treatment_given = appointment.treatment_given ?
            (typeof appointment.treatment_given === 'string' ?
                JSON.parse(appointment.treatment_given) :
                appointment.treatment_given) : [];

        const payment = appointment.payment || '';

        setRecommendationData({
            appointment_id: appointment.id || '',
            patient_name: appointment.patient_name || '',
            phone_number: appointment.phone || '',
            visit_number: appointment.visit_number?.toString() || '',
            medical_history: medical_history,
            treatment_given: treatment_given,
            treatment_plan: appointment.treatment_plan || '',
            medications: appointment.medications || '',
            payment: payment
        });
        setShowRecommendationModal(true);
    } catch (error) {
        console.error("Error parsing data:", error);
        alert("Error loading recommendation data");
    }
};


const fetchCurrentNumbers = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://www.alrehmandentalcare.com/api/current-numbers', {
            headers: { Authorization: `Bearer ${token}` }
        });
        setCurrentNumbers(response.data);
    } catch (error) {
        console.error('Error fetching current numbers:', error);
        setMessage('Error fetching current numbers');
    }
};

const updateCurrentNumber = async (doctorName, number) => {
    try {
        const token = localStorage.getItem('token');
        await axios.post('https://www.alrehmandentalcare.com/api/update-current-number', {
            doctorName,
            number
        }, {
            headers: { Authorization: `Bearer ${token}` }
        });
        fetchCurrentNumbers();
    } catch (error) {
        setMessage('Error updating current number');
    }
};

const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this appointment?')) {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.delete(`https://www.alrehmandentalcare.com/api/appointments/delete/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.data.success) {
                setMessage('Appointment deleted successfully');
                fetchAppointments();
            }
        } catch (error) {
            setMessage(error.response?.data?.message || 'Error deleting appointment');
        }
    }
};

const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
        const token = localStorage.getItem('token');
        
        const formattedData = {
            ...updateFormData,
            appointment_date: formatDateForUpdate(updateFormData.appointment_date),
            appointment_time: updateFormData.appointment_time,
            visit_number: parseInt(updateFormData.visit_number) || null
        };

        const response = await axios.put(
            `https://www.alrehmandentalcare.com/api/appointments/update/${selectedAppointment.id}`,
            formattedData,
            {
                headers: { 
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        
        if (response.data.success) {
            setMessage('Appointment updated successfully');
            setShowUpdateModal(false);
            fetchAppointments();
        }
    } catch (error) {
        console.error('Update error:', error);
        setMessage(error.response?.data?.message || 'Error updating appointment');
    }
};

const openUpdateModal = (appointment) => {
    setSelectedAppointment(appointment);
    setUpdateFormData({
        patient_name: appointment.patient_name,
        email: appointment.email,
        phone: appointment.phone,
        appointment_date: appointment.formatted_date,
        appointment_time: appointment.formatted_time,
        service: appointment.service,
        visit_number: appointment.visit_number?.toString() || '',
        additional_message: appointment.additional_message || ''
    });
    setShowUpdateModal(true);
};

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData(prev => ({
        ...prev,
        [name]: value
    }));
};

const filteredAppointments = appointments.filter(appointment => {
    const matchesName = appointment.patient_name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDate = !searchDate || appointment.formatted_date === formatDateForUpdate(searchDate);
    return matchesName && matchesDate;
});

const groupAppointmentsByPatient = (appointments) => {
    return appointments.reduce((acc, apt) => {
        const phone = apt.phone;
        if (!acc[phone]) {
            acc[phone] = [];
        }
        acc[phone].push(apt);
        return acc;
    }, {});
};











const openRecommendationModal = (appointment) => {
    // Parse medical_history and treatment_given safely
    let parsedMedicalHistory = [];
    let parsedTreatmentGiven = [];

    try {
        parsedMedicalHistory = appointment.medical_history ? 
            (typeof appointment.medical_history === 'string' ? 
                JSON.parse(appointment.medical_history) : 
                appointment.medical_history) : [];
    } catch (e) {
        console.warn('Error parsing medical history:', e);
        parsedMedicalHistory = [];
    }

    try {
        parsedTreatmentGiven = appointment.treatment_given ? 
            (typeof appointment.treatment_given === 'string' ? 
                JSON.parse(appointment.treatment_given) : 
                appointment.treatment_given) : [];
    } catch (e) {
        console.warn('Error parsing treatment given:', e);
        parsedTreatmentGiven = [];
    }


    const payment = appointment.payment || '';

    setRecommendationData({
        appointment_id: appointment.id,
        patient_name: appointment.patient_name,
        phone_number: appointment.phone,
        visit_number: appointment.visit_number?.toString() || '',
        medical_history: parsedMedicalHistory,
        treatment_given: parsedTreatmentGiven,
        treatment_plan: appointment.treatment_plan || '',
        medications: appointment.medications || '',
        payment: payment 
    });
    setShowRecommendationModal(true);
};

// Add this helper function at the top of your component
const parseJSONSafely = (jsonString, defaultValue = []) => {
    if (!jsonString) return defaultValue;
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        console.warn('Failed to parse JSON:', e);
        return defaultValue;
    }
};

return (
    <div className="admin-dashboard">
        <h2>Admin Dashboard</h2>
        {message && (
            <div className="message" onClick={() => setMessage('')}>
                {message}
            </div>
        )}

        <div className="current-numbers-admin">
            <h3>Update Current Appointment Numbers</h3>
            <div className="doctor-number-inputs">
                {['Dr. Zirva', 'Dr. Adnan', 'Dr. Junaid Khan', 'Dr. Maimona', 'Dr. Maha'].map(doctor => (
                    <div key={doctor} className="doctor-number-input">
                        <label>{doctor}</label>
                        <input 
                            type="number" 
                            min="0"
                            value={currentNumbers.find(d => d.doctor_name === doctor)?.current_appointment_number || 0}
                            onChange={(e) => updateCurrentNumber(doctor, e.target.value)}
                        />
                    </div>
                ))}
            </div>
        </div>

        <div className="search-filters">
            <input
                type="text"
                placeholder="Search by patient name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <input
                type="date"
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
            />
        </div>

        {loading ? (
<div className="loading-spinner">Loading...</div>
) : (
<div className="patients-history">
{Object.entries(groupAppointmentsByPatient(filteredAppointments)).map(([phone, appointments]) => (
<div key={phone} className="patient-appointments">
<h3>Patient: {appointments[0].patient_name}</h3>
<p>Phone: {phone}</p>
<div className="appointments-timeline">
{appointments.map((apt, index) => (
<div key={index} className="appointment-card">
<p>Visit #{apt.visit_number}</p>
<p>Date: {apt.formatted_date}</p>
<p>Doctor: {apt.doctor_name}</p>
<p>Service: {apt.service}</p>
<p>Time: {apt.time}</p>
<p>Additional Information: {apt.additional_message}</p>


                        
                        {(apt.medical_history || apt.treatment_given || apt.treatment_plan || apt.medications || apt.payment) ? (
<div className="recommendation-info">
    <h4>Doctor's Recommendation</h4>
    {apt.medical_history && (
        <p>
            <strong>Medical History:</strong>{' '}
            {(() => {
                try {
                    return typeof apt.medical_history === 'string' 
                        ? JSON.parse(apt.medical_history).join(', ')
                        : Array.isArray(apt.medical_history)
                            ? apt.medical_history.join(', ')
                            : apt.medical_history;
                } catch (e) {
                    return apt.medical_history;
                }
            })()}
        </p>
    )}
    {apt.treatment_given && (
        <p>
            <strong>Treatment Given:</strong>{' '}
            {(() => {
                try {
                    return typeof apt.treatment_given === 'string'
                        ? JSON.parse(apt.treatment_given).join(', ')
                        : Array.isArray(apt.treatment_given)
                            ? apt.treatment_given.join(', ')
                            : apt.treatment_given;
                } catch (e) {
                    return apt.treatment_given;
                }
            })()}
        </p>
    )}


{apt.payment && (
        <p>
            <strong>Payment:</strong>{' '}
            {(() => {
                try {
                    return typeof apt.payment === 'string'
                        ? JSON.parse(apt.payment).join(', ')
                        : Array.isArray(apt.payment)
                            ? apt.payment.join(', ')
                            : apt.payment;
                } catch (e) {
                    return apt.payment;
                }
            })()}
        </p>
    )}
    {apt.treatment_plan && <p><strong>Treatment Plan:</strong> {apt.treatment_plan}</p>}
    {apt.medications && <p><strong>Medications:</strong> {apt.medications}</p>}
    {apt.payment && <p><strong>payment:</strong> {apt.payment}</p>}

</div>
) : null}
<div className="appointment-actions">
    <button onClick={() => handleDelete(apt.id)}>Delete</button>
    <button onClick={() => openUpdateModal(apt)}>Update</button>
    <button
        className="recommend-btn"
        onClick={() => openRecommendationModal(apt)}
    >
        {apt.medical_history ? 'Update Recommendation' : 'Add Recommendation'}
    </button>
   
</div>
</div>
))}
</div>
</div>
))}
</div>
)}
{showUpdateModal && (
<div className="modal-overlay">
<div className="update-modal">
<div className="modal-content">
<h3>Update Appointment</h3>
<form onSubmit={handleUpdateSubmit}>
<div className="form-group">
<input type="text" name="patient_name" value={updateFormData.patient_name} onChange={handleInputChange} placeholder="Patient Name" required />
</div>
<div className="form-group">
<input type="email" name="email" value={updateFormData.email} onChange={handleInputChange} placeholder="Email"  />
</div>
<div className="form-group">
<input type="tel" name="phone" value={updateFormData.phone} onChange={handleInputChange} placeholder="Phone" required />
</div>
<div className="form-group">
<input type="date" name="appointment_date" value={updateFormData.appointment_date} onChange={handleInputChange} required />
</div>
<div className="form-group">
<input type="time" name="appointment_time" value={updateFormData.appointment_time} onChange={handleInputChange} required />
</div>
<div className="form-group">
<input type="text" name="service" value={updateFormData.service} onChange={handleInputChange} placeholder="Service" required />
</div>
<div className="form-group">
<input type="number" name="visit_number" value={updateFormData.visit_number} onChange={handleInputChange} placeholder="Visit Number" />
</div>
<div className="form-group">
<textarea name="additional_message" value={updateFormData.additional_message} onChange={handleInputChange} placeholder="Additional Message" />
</div>
<div className="modal-buttons">
<button type="submit" className="update-btn">
Update
</button>
<button
type="button"
className="cancel-btn"
onClick={() => setShowUpdateModal(false)}
>
Cancel
</button>
</div>
</form>
</div>
</div>
</div>
)}

{showRecommendationModal && (
<div className="modal-overlay">
<div className="recommendation-modal">
<div className="modal-content">
<h3>Add Doctor's Recommendation</h3>
<form onSubmit={handleRecommendationSubmit}>
<div className="form-group">
<label>Medical History</label>
<div className="checkbox-group">
{medicalHistoryOptions.map(option => (
<label key={option} className="checkbox-label">
<input
type="checkbox"
checked={recommendationData.medical_history.includes(option)}
onChange={() => handleCheckboxChange('medical_history', option)}
/>
{option}
</label>
))}
</div>
</div>
                <div className="form-group">
                    <label>Treatment Given</label>
                    <div className="checkbox-group">
                        {treatmentOptions.map(option => (
                            <label key={option} className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={recommendationData.treatment_given.includes(option)}
                                    onChange={() => handleCheckboxChange('treatment_given', option)}
                                />
                                {option}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="form-group">
                    <label>Treatment Plan</label>
                    <textarea
                        name="treatment_plan"
                        value={recommendationData.treatment_plan}
                        onChange={(e) => setRecommendationData({
                            ...recommendationData,
                            treatment_plan: e.target.value
                        })}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Medications</label>
                    <textarea
                        name="medications"
                        value={recommendationData.medications}
                        onChange={(e) => setRecommendationData({
                            ...recommendationData,
                            medications: e.target.value
                        })}
                        required
                    />
                </div>


                <div className="form-group">
                        <label>Payment</label>
                        <input
                            type="text"
                            name="payment"
                            value={recommendationData.payment}
                            onChange={(e) => setRecommendationData({
                                ...recommendationData,
                                payment: e.target.value
                            })}
                            placeholder="Enter payment amount"
                            required
                        />
                    </div>

                <div className="modal-buttons">
                    <button type="submit" className="update-btn">
                        Save Recommendation
                    </button>
                    <button
                        type="button"
                        className="cancel-btn"
                        onClick={() => setShowRecommendationModal(false)}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
)}
</div>
);
};
export default AdminDashboard;
